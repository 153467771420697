@font-face {
font-family: '__Ubuntu_737716';
src: url(/_next/static/media/7f7f7f543df27dc4-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__Ubuntu_Fallback_737716';src: local("Arial");ascent-override: 91.04%;descent-override: 18.46%;line-gap-override: 2.74%;size-adjust: 102.37%
}.__className_737716 {font-family: '__Ubuntu_737716', '__Ubuntu_Fallback_737716'
}

.Tree_app__tljbO {
  height: 100%;
}

.Tree_treeRoot__xZIND {
  height: 100%;
}

.Tree_draggingSource__kPRU1 {
  opacity: 0.3;
}

.Tree_dropTarget__TeF1a {
  background-color: #e8f0fe;
}

.Tree_listStyle__pRR0h {
  list-style-type: none;
  align-items: center;
  padding-top: 4px;
}

.Tree_container__t05IO {
  padding-left: 0px;
}

.Tree_placeholderContainer__AFMD2 {
  position: relative;
}

.styles_nodeWrapper__vtO2P {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 32px;
  -webkit-padding-end: 8px;
          padding-inline-end: 8px;
  -webkit-padding-start: 8px;
          padding-inline-start: 8px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  z-index: 3;
  font-size: small;
}

.styles_nodeWrapper__vtO2P:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.styles_nodeWrapper__vtO2P .styles_expandIconWrapper__jSkp8 {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transform: rotate(0deg);
}

.styles_nodeWrapper__vtO2P .styles_expandIconWrapper__jSkp8.styles_isOpen__phdrf {
  transform: rotate(180deg);
}

.styles_nodeWrapper__vtO2P .styles_expandIconWrapper__jSkp8.styles_isOpen__phdrf svg path {
  fill: #4f5272;
}

.styles_nodeWrapper__vtO2P .styles_labelGridItem__GYvGV {
  -webkit-padding-start: 8px;
          padding-inline-start: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styles_pipeY__urepA {
  position: absolute;
  border-left: 2px solid #e7e7e7;
  left: -7px;
  top: -7px;
}

.styles_pipeX__KSZRC {
  position: absolute;
  left: -7px;
  top: 15px;
  height: 2px;
  background-color: #e7e7e7;
  z-index: -1;
}

.styles_wrapper__F6VVQ {
  font-family: sans-serif;
  padding: 5px;
}

.styles_treeRoot__VRc45 {
  list-style-type: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  padding: 5px;
  position: relative;
}

.styles_treeRoot__VRc45 ul {
  list-style-type: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  position: relative;
  padding-bottom: 5px;
}

.styles_treeRoot__VRc45 > li:after {
  display: none;
}

.styles_wrapper__F6VVQ .styles_draggingSource__D4vOU {
  opacity: 0.3;
}

.styles_wrapper__F6VVQ .styles_placeholder__2UnOQ {
  position: relative;
}

.styles_wrapper__F6VVQ > ul > li > .styles_nodeWrapper__vtO2P > div.styles_pipeY__urepA {
  display: none;
}

.styles_wrapper__F6VVQ li:has(> .styles_dropTarget__Bxxix) {
  outline: 3px solid #e8f0fe;
  border-radius: 4px;
}

.Tree_app__GZaqI {
  height: 100%;
}

.Tree_treeRoot__JPAFv {
  height: 100%;
  padding-left: 0px;
}

.Tree_draggingSource__osd0G {
  opacity: 0.3;
}

.Tree_dropTarget__V8MAq {
  background-color: #e8f0fe;
}
.Tree_listStyle__6X65A {
  list-style-type: none;
  padding-left: 0px;
}

.Tree_container__zGDlT {
  padding-left: 15px;
}

